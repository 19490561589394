import React, { useRef } from "react";
import styles from "./Navbar.module.scss";
/* import globalStyles from "../../global.module.scss"; */

import { NavLink as Link } from "react-router-dom";

import MenuItems from "./MenuItems.js";
/* import logo from "../../assets/images/gplogo.webp"; */

const options = [
  { name: "English", value: "en-US" },
  { name: "Svenska", value: "sv" },
];

const Navbar = ({ lang, setLang }) => {
  const mobileMenuRef = useRef();
  const toggleMobileMenu = () => mobileMenuRef.current?.classList.toggle(styles.active);

  const handleLang = (event) => {
    console.log(event.target.value);
    setLang(event.target.value);
  };

  return (
    <nav>
      <div className={`${styles.container}`}>
        <h1 href="/#" className={styles.logo}>
          {/* <img alt="logo" src={logo} /> */}
          Gothenburgs preschool
        </h1>

        <ul ref={mobileMenuRef}>
        <select onChange={(e) => handleLang(e)} value={lang}>
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
          {MenuItems.map((item, index) => (
            <Link exact to={item.url} key={index}>
              <li className={item.style === "cta" ? styles.cta : ""}>{lang === "en-US" ? item.title : item.titleSwe}</li>
            </Link>
          ))}
        </ul>

        <div className={styles.mobileMenu} onClick={() => toggleMobileMenu()}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
