const MenuItems = [
  { title: "Home", titleSwe: "Hem", url: "/" },
  { title: "Staff", titleSwe: "Personal", url: "/staff" },
  { title: "Preschool Concepts", titleSwe: "Förskolekoncept", url: "/preschool-concepts" },
  { title: "Outdoor Program", titleSwe: "Utomhusprogram", url: "/outdoor-program" },
  { title: "Preschool Måsen", titleSwe: "Förskolan Måsen", url: "/masen" },
  { title: "Contact", titleSwe: "Kontakt", url: "/contact" },
  { title: "Application", titleSwe: "Ansökan", url: "/application", style: "cta" },
];

export default MenuItems;
