import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import styles from "./Contact.module.scss";
import client from "../services/contentful.js";
import Markdown from "react-markdown";

const Contact = ({ lang, setLang }) => {
  const [isLoading, setisLoading] = useState(true);
  const [contactInfo, setContactInfo] = useState([]);
  const [image, setImage] = useState("");
  const [headerTitle, setHeaderTitle] = useState("CONTACT");

  const [numbers, setNumbers] = useState([]);
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    client
      .getEntry("6AHFvlY20B2VR5YTI1C1U8", { locale: lang })
      .then((entry) => {
        setContactInfo(entry.fields);
        setHeaderTitle(entry.fields.headerTitle);
        if (entry.fields.headerImage.fields !== undefined) {
          setImage("https://" + entry.fields.headerImage.fields.file.url);
        }
        setisLoading(false);
      });
    client
      .getEntries({ content_type: "telephone", locale: lang })
      .then((entries) => {
        setNumbers(entries.items);
      });
    client
      .getEntries({ content_type: "email", locale: lang })
      .then((entries) => {
        setEmails(entries.items);
      });
  }, [lang]);

  return (
    <Layout title={headerTitle} image={image} lang={lang} setLang={setLang}>
      {isLoading ? (
        ""
      ) : (
        <div className={styles.container}>
          <div className={styles.phone}>
            <h3 className={styles.title}>Telephone</h3>
            {numbers.map((number, index) => (
              <div className={styles.person} key={index}>
                <p>{number.fields.name}</p>
                <p>{number.fields.number}</p>
              </div>
            ))}
          </div>

          <hr />

          <div className={styles.email}>
            <h3 className={styles.title}>Mail</h3>
            {emails.map((email, index) => (
              <div className={styles.person} key={index}>
                <p>{email.fields.name}</p>
                <p>
                  <span className={styles.dummy}>dummy</span>
                  {email.fields.email}
                  <span className={styles.dummy}>dummy</span>
                </p>
              </div>
            ))}
          </div>

          <hr />

          <div className={styles.address}>
            <h3 className={styles.title}>locations</h3>
            <div className={styles.location}>
              <p>Gothenburgs Preschool</p>
              <p>Krukmakaregatan 1A</p>
              <p>414 60 Göteborg</p>
              <iframe
                title="Gothenburg Preschool Krukmakaregatan"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1066.1498853780317!2d11.922623083671144!3d57.694376711254236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff335c2ac7c9b%3A0x5c30ffd119b0560!2sGothenburgs%20Preschool!5e0!3m2!1sen!2sse!4v1605780274271!5m2!1sen!2sse"
                frameBorder={0}
                allowFullScreen={false}
                aria-hidden={false}
                tabIndex={0}
              ></iframe>
            </div>
            <div className={styles.location}>
              <p>Gothenburgs Preschool</p>
              <p>Söderlingsgatan 9B</p>
              <p>414 60 Göteborg</p>
              <iframe
                title="Gothenburg Preschool Söderlingsgatan"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2132.4993768268896!2d11.934358716104382!3d57.69098524832038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff33908a47537%3A0x871d988712579f34!2sGothenburgs%20Preschool!5e0!3m2!1sen!2sse!4v1605781616423!5m2!1sen!2sse"
                frameBorder={0}
                allowFullScreen={false}
                aria-hidden={false}
                tabIndex={0}
              ></iframe>
            </div>
            <div className={`${styles.location} ${styles.cent}`}>
              <p>Förskolan Måsen</p>
              <p>Masthuggsliden 5</p>
              <p>413 18 Göteborg</p>
              <iframe
                title="Gothenburg Preschool Måsen"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d753.8363169998405!2d11.941881057862233!3d57.69656308784875!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4d798c31da94dbf5!2zTcOlc2Vu!5e0!3m2!1ssv!2sse!4v1661418917845!5m2!1ssv!2sse"
                frameBorder={0}
                allowFullScreen={false}
                aria-hidden={false}
                tabIndex={0}
              ></iframe>
            </div>
          </div>

          <hr />

          <div>
            <h3 className={styles.title}>job inquiries</h3>
            <Markdown
              children={contactInfo.jobInquiries}
              allowDangerousHtml={true}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Contact;
