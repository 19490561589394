import React, { useEffect, useState } from "react";
import Employees from "../components/Employees/Employees.jsx";
import Layout from "../components/Layout/Layout";
import client from "../services/contentful.js";

const Staff = ({ lang, setLang }) => {
  const [image, setImage] = useState("");
  const [headerTitle, setHeaderTitle] = useState("STAFF");
  
  useEffect(() => {
    window.scrollTo(0, 0);
    client.getEntry("6GpV7gGjjcsnZtTMeJGGW6", { locale: lang }).then((entry) => {
      setHeaderTitle(entry.fields.headerTitle);
      if (entry.fields.headerImage !== undefined) {
        setImage("https://" + entry.fields.headerImage.fields.file.url);
      }
    });
  }, [lang]);
  
  return (
    <Layout title={headerTitle} image={image} lang={lang} setLang={setLang}>
      <Employees />
    </Layout>
  );
};

export default Staff;
