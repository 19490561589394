import React from "react";
import styles from "./Layout.module.scss";
import globalStyles from "../../global.module.scss";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Layout = ({ height, title, image, lang, setLang, children }) => {
  return (
    <div className={styles.layout}>
      <div
        className={styles.hero}
        style={{
          height: height,
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), URL('${
            image === "" || undefined || null ? "" : image
          }')`,
        }}
      >
        <Navbar lang={lang} setLang={setLang} />

        <header>
          <h1>{title}</h1>
        </header>
      </div>
      <main className={globalStyles.max_width}>{children}</main>

      <Footer lang={lang} />
    </div>
  );
};

export default Layout;
