import React, { useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import globalStyles from "../../global.module.scss";
import MenuItems from "../Navbar/MenuItems.js";
import Markdown from "react-markdown";
import client from "../../services/contentful.js";
import { NavLink as Link } from "react-router-dom";
import flogo from "../../assets/images/facebook.png";

const Footer = ({ lang }) => {
  const [isLoading, setisLoading] = useState(true);
  const [footerContent, setFooterContent] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    client.getEntry("7LwDzC2xAdHUQC1vHc1Tyd", { locale: lang }).then((entry) => {
      setFooterContent(entry.fields);
      setisLoading(false);
    });
  }, [lang]);

  return (
    <div className={styles.container}>
      <div className={`${globalStyles.max_width} ${styles.content}`}>
        {isLoading ? (
          ""
        ) : (
          <div className={styles.content}>
            <div>
              <h1>{lang === "en-US" ? "About" : "Om oss"}</h1>
              <Markdown children={footerContent.about} allowDangerousHtml={true} />
            </div>
            <div>
              <h1>Quick Links</h1>
              <ul>
                {MenuItems.map((item, index) => (
                  <Link exact to={item.url} activeClassName={styles.selected} key={index}>
                    <li className={item.style === "cta" ? styles.cta : ""}>
                      {lang === "en-US" ? item.title : item.titleSwe}
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
            <div>
              <h1>Facebook</h1>
              <ul>
                <a
                  href="https://www.facebook.com/Gothenburgs-Preschool-464905613583761/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img className={styles.social} src={flogo} alt="facebook" />
                </a>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
