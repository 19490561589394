import React, { useEffect, useState } from "react";
import styles from "./Employees.module.scss";
import client from "../../services/contentful.js";

import placeholder from "../../assets/images/avatar_placeholder.png";

const Staff = () => {
  const [isLoading, setisLoading] = useState(true);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    client.getEntries({ content_type: "employee" }).then((entries) => {
      setEmployees(entries.items);
      setisLoading(false);
    });
  }, []);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <></>
      ) : (
        <ul className={styles.employees}>
          {employees.map((employee, index) => (
            <li className={styles.employee} key={index}>
              <img
                src={
                  employee.fields.image.fields === undefined
                    ? placeholder
                    : `https:${employee.fields.image.fields.file.url}`
                }
                alt="employee_photo"
              />
              <h1>{employee.fields.name}</h1>
              <p>{employee.fields.position}</p>
              <p>{employee.fields.location}</p>
              <p className={styles.quote}>
                <span>"</span>
                {employee.fields.quote}
                <span>"</span>
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Staff;
