import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import styles from "./Application.module.scss";
import client from "../services/contentful.js";
import Markdown from "react-markdown";
import { Form, Formik, useField, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ModalPopup from "../components/ModalPopup/ModalPopup";

const CustomTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className={props.className}>
      <label htmlFor={props.id || props.name}>{label}</label>
      <br />
      <input className={styles.text_input} {...field} {...props} id={props.name} />
      {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
      <div style={{ marginBottom: "4px" }} />
      <br />
    </div>
  );
};

const CustomCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField(props, "checkbox");

  return (
    <>
      <br />
      <label>
        <input type="checkbox" {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? <div className={styles.error}>{meta.error}</div> : null}
    </>
  );
};

const Application = ({ lang, setLang }) => {
  const [isLoading, setisLoading] = useState(true);
  const [applicationInfo, setApplicationInfo] = useState([]);
  const [image, setImage] = useState("");
  const [headerTitle, setHeaderTitle] = useState("APPLICATION");
  const [application, setApplication] = useState(true);
  const [reApplicationActive, setReApplicationActive] = useState(false);
  const [reapplication, setReApplication] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    client.getEntry("pP7h669llLjWssLAzr061", { locale: lang }).then((entry) => {
      setApplicationInfo(entry.fields);
      setHeaderTitle(entry.fields.headerText);
      setReApplicationActive(entry.fields.reApplicationActive);
      if (entry.fields.headerImage.fields !== undefined) {
        setImage("https://" + entry.fields.headerImage.fields.file.url);
      }
      setisLoading(false);
    });
  }, [lang]);

  const handleApplication = (data) => {
    fetch("https://europe-west2-core-dev-161521.cloudfunctions.net/gbgps-application-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    }).then(function (response) {
      return response.json();
    });
  };

  const handleReApplication = (data) => {
    fetch("https://europe-west2-core-dev-161521.cloudfunctions.net/gbgps-reapplication-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    }).then(function (response) {
      return response.json();
    });
  };

  const showReApplication = () => {
    if (application === true) {
      setApplication(false);
      setReApplication(true);
    }
  };

  const showApplication = () => {
    if (reapplication === true) {
      setReApplication(false);
      setApplication(true);
    }
  };

  return (
    <Layout title={headerTitle} image={image} lang={lang} setLang={setLang}>
      <div className={styles.container}>
        {isLoading ? (
          ""
        ) : (
          <>
            <ModalPopup open={open} close={() => setOpen(false)} lang={lang} />
            <div className={styles.text}>
              <Markdown children={applicationInfo.applicationContent} allowDangerousHtml={true} />
            </div>

            <br />

            <button onClick={() => showApplication()} className={application ? styles.active : ""}>
              {lang === "en-US" ? "Application Form" : "Ansökningsformulär"}
            </button>
            <button onClick={() => showReApplication()} className={reapplication ? styles.active : ""}>
              {lang === "en-US" ? "Re-Application Form" : "Återansökningsformulär"}
            </button>

            <br />

            {application ? (
              <Formik
                initialValues={{
                  gender: "",
                  pnumber: "",
                  fname: "",
                  lname: "",
                  address: "",
                  startdate: "",
                  location: "",
                  outdoorprogram: "",
                  swedishresidency: "",
                  preschool: "",
                  additionalsupport: "",
                  guardian1fname: "",
                  guardian1lname: "",
                  guardian1dob: "",
                  guardian1phone: "",
                  guardian1address: "",
                  guardian1email: "",
                  guardian1placeofemployment: "",
                  guardian2fname: "",
                  guardian2lname: "",
                  guardian2dob: "",
                  guardian2phone: "",
                  guardian2address: "",
                  guardian2email: "",
                  guardian2placeofemployment: "",
                  languages: "",
                  acceptedTerms: false,
                }}
                validationSchema={Yup.object({
                  gender: Yup.string().required("*Required"),
                  pnumber: Yup.string().required("*Required"),
                  fname: Yup.string().min(2, "Must be at least 2 characters").required("*Required"),
                  lname: Yup.string().min(2, "Must be at least 2 characters").required("*Required"),
                  address: Yup.string().required("*Required"),
                  startdate: Yup.string().required("*Required"),
                  location: Yup.string().required("*Required"),
                  preschool: Yup.string().required("*Required"),
                  outdoorprogram: Yup.string().required("*Required"),
                  swedishresidency: Yup.string().required("*Required"),
                  additionalsupport: Yup.string().required("*Required"),
                  guardian1fname: Yup.string().min(2, "Must be at least 2 characters").required("*Required"),
                  guardian1lname: Yup.string().min(2, "Must be at least 2 characters").required("*Required"),
                  guardian1dob: Yup.string().required("*Required"),
                  guardian1phone: Yup.string().required("*Required"),
                  guardian1address: Yup.string().required("*Required"),
                  guardian1email: Yup.string().email("Invalid email address").required("*Required"),
                  guardian1placeofemployment: Yup.string().required("*Required"),
                  languages: Yup.string().required("*Required"),
                  acceptedTerms: Yup.boolean()
                    .required("*Required")
                    .oneOf([true], "You must accept the terms and conditions"),
                })}
                onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
                  setTimeout(() => {
                    handleApplication(
                      '[["' +
                        new Date().toISOString() +
                        '", "' +
                        values.gender +
                        '", "' +
                        values.pnumber +
                        '", "' +
                        values.fname +
                        '", "' +
                        values.lname +
                        '", "' +
                        values.address +
                        '", "' +
                        values.startdate +
                        '", "' +
                        values.location +
                        '", "' +
                        values.outdoorprogram +
                        '", "' +
                        values.swedishresidency +
                        '", "' +
                        values.preschool +
                        '", "' +
                        values.additionalsupport +
                        '", "' +
                        values.guardian1fname +
                        '", "' +
                        values.guardian1lname +
                        '", "' +
                        values.guardian1dob +
                        '", "' +
                        values.guardian1phone +
                        '", "' +
                        values.guardian1address +
                        '", "' +
                        values.guardian1email +
                        '", "' +
                        values.guardian1placeofemployment +
                        '", "' +
                        values.guardian2fname +
                        '", "' +
                        values.guardian2lname +
                        '", "' +
                        values.guardian2dob +
                        '", "' +
                        values.guardian2phone +
                        '", "' +
                        values.guardian2address +
                        '", "' +
                        values.guardian2email +
                        '", "' +
                        values.guardian2placeofemployment +
                        '", "' +
                        values.languages +
                        '"]]'
                    );
                    resetForm();
                    window.scrollTo(0, 0);
                    setOpen(true);
                    setSubmitting(false);
                  }, 2000);
                }}
              >
                {(props) => (
                  /* the id on the <Form> is used by emailJS to get the form */
                  <Form onSubmit={props.handleSubmit} id="formikForm" className={styles.applicationForm}>
                    <h1>{lang === "en-US" ? "Application Form" : "Ansökningsformulär"}</h1>

                    <div className={styles.gender}>
                      <label htmlFor="gender">{lang === "en-US" ? "Gender" : "Kön"}</label>
                      <label>
                        <Field type="radio" name="gender" value="Boy" />
                        {lang === "en-US" ? "Boy" : "Pojke"}
                      </label>
                      <label>
                        <Field type="radio" name="gender" value="Girl" />
                        {lang === "en-US" ? "Girl" : "Flicka"}
                      </label>
                      <ErrorMessage name="gender" component="div" className={styles.error} />
                    </div>
                    <br />

                    <CustomTextInput
                      label={lang === "en-US" ? "First name" : "Förnamn"}
                      name="fname"
                      type="text"
                      className={styles.fname}
                    />
                    <CustomTextInput
                      label={lang === "en-US" ? "Last name" : "Efternamn"}
                      name="lname"
                      type="text"
                      className={styles.lname}
                    />
                    <CustomTextInput
                      label={
                        lang === "en-US"
                          ? "Personal number - if not available then Date of Birth (year/month/day)"
                          : "Personnummer - om det inte finns skriv då födelsedatum (år/månad/dag)"
                      }
                      name="pnumber"
                      type="text"
                      className={styles.pnumber}
                    />
                    <CustomTextInput
                      label={
                        lang === "en-US"
                          ? "Swedish Address (if not available then current address)"
                          : "Svensk Adress (om ni inte har ännu skriv då nuvarande adress)"
                      }
                      name="address"
                      type="text"
                      className={styles.address}
                    />
                    <CustomTextInput
                      label={lang === "en-US" ? "Preferred start date" : "Önskat startdatum"}
                      name="startdate"
                      type="text"
                      className={styles.startdate}
                    />

                    <CustomTextInput
                      label={
                        lang === "en-US"
                          ? "How long will you be a resident in Sweden"
                          : "Hur länge kommer ni att vara bosatt i Sverige"
                      }
                      name="swedishresidency"
                      type="text"
                      className={styles.swedishresidency}
                    />
                    <CustomTextInput
                      label={
                        lang === "en-US"
                          ? "Is your child currently in preschool in Sweden (If yes, please specify)"
                          : "Går ditt barn för närvarande i en förskola i Sverige (om ja, ange vilken)"
                      }
                      name="preschool"
                      type="text"
                      className={styles.preschool}
                    />
                    <CustomTextInput
                      label={
                        lang === "en-US"
                          ? "Does your child have any special needs that require additional support"
                          : "Har ditt barn några särskilda behov som behöver ytterligare stöd"
                      }
                      name="additionalsupport"
                      type="text"
                      className={styles.additionalsupport}
                    />

                    <div className={styles.location}>
                      <label htmlFor="location">{lang === "en-US" ? "Preferred location" : "Önskad plats"}</label>
                      <label>
                        <Field type="radio" name="location" value="Krukmakaregatan 1A" />
                        Krukmakaregatan 1A
                      </label>
                      <label>
                        <Field type="radio" name="location" value="Söderlingsgatan 9A" />
                        Söderlingsgatan 9A
                      </label>
                      <label>
                        <Field type="radio" name="location" value="Either" />
                        {lang === "en-US" ? "Either" : "Vilken som"}
                      </label>
                      <label>
                        <Field type="radio" name="location" value="Måsen" />
                        Måsen
                      </label>
                      <ErrorMessage name="location" component="div" className={styles.error} />
                    </div>
                    <br />

                    <div className={styles.outdoorprogram}>
                      <label htmlFor="outdoorprogram" id="my-radio-group">
                        <strong>
                          {lang === "en-US"
                            ? "Would you like to apply for the Outdoor program at our Söderlingsgatan location (available for ages 4-5)"
                            : "Vill du ansöka till utomhusprogrammet på Söderlingsgatan (för åldrarna 4-5)"}
                        </strong>
                      </label>
                      <div>
                        <label>
                          <Field type="radio" name="outdoorprogram" value="Yes" />
                          {lang === "en-US" ? "Yes" : "Ja"}
                        </label>
                        <label>
                          <Field type="radio" name="outdoorprogram" value="No" />
                          {lang === "en-US" ? "No" : "Nej"}
                        </label>
                        <ErrorMessage name="outdoorprogram" component="div" className={styles.error} />
                      </div>
                    </div>
                    <br />

                    <h2 className={styles.guardianinfo}>
                      {lang === "en-US" ? "Guardian Info" : "Vårdnadshavare information"}
                    </h2>
                    <div className={styles.guardian_one}>
                      <CustomTextInput
                        label={lang === "en-US" ? "First name" : "Förnamn"}
                        name="guardian1fname"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Last name" : "Efternamn"}
                        name="guardian1lname"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Date of birth" : "Födelsedatum"}
                        name="guardian1dob"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Phone No." : "Telefonnummer"}
                        name="guardian1phone"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Address" : "Adress"}
                        name="guardian1address"
                        type="text"
                      />
                      <CustomTextInput label="E-mail" name="guardian1email" type="text" />
                      <CustomTextInput
                        label={lang === "en-US" ? "Company you work for in Sweden" : "Företag du arbetar för i Sverige"}
                        name="guardian1placeofemployment"
                        type="text"
                      />
                    </div>
                    <div className={styles.guardian_two}>
                      <CustomTextInput
                        label={lang === "en-US" ? "First name" : "Förnamn"}
                        name="guardian2fname"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Last name" : "Efternamn"}
                        name="guardian2lname"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Date of birth" : "Födelsedatum"}
                        name="guardian2dob"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Phone No." : "Telefonnummer"}
                        name="guardian2phone"
                        type="text"
                      />
                      <CustomTextInput
                        label={lang === "en-US" ? "Address" : "Adress"}
                        name="guardian2address"
                        type="text"
                      />
                      <CustomTextInput label="E-mail" name="guardian2email" type="text" />
                      <CustomTextInput
                        label={lang === "en-US" ? "Company you work for in Sweden" : "Företag du arbetar för i Sverige"}
                        name="guardian2placeofemployment"
                        type="text"
                      />
                    </div>

                    <CustomTextInput
                      label={lang === "en-US" ? "Languages spoken at home" : "Språk som talas hemma"}
                      name="languages"
                      type="text"
                      className={styles.languagesspoken}
                    />
                    <div className={styles.terms}>
                      <CustomCheckbox name="acceptedTerms">
                        {lang === "en-US"
                          ? "We hereby provide consent to Gothenburgs Preschool AB to store the above information for their waiting list. You can read more about the GDPR policy on our"
                          : "Vi ger härmed samtycke till Gothenburgs Preschool AB att lagra ovanstående information till deras väntelista. Du kan läsa mer om GDPR-policy på vår"}
                        <a href="/gdpr" rel="noopener noreferrer" target="_blank">
                          GDPR Page
                        </a>
                      </CustomCheckbox>
                    </div>
                    <br />
                    <button className={styles.submit} type="submit">
                      {props.isSubmitting ? "Loading..." : "Submit"}
                    </button>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                {reApplicationActive ? (
                  <Formik
                    initialValues={{
                      pnumber2: "",
                      fname2: "",
                      lname2: "",
                      approxdate: "",
                      newinfo: "",
                      acceptedTerms2: false,
                    }}
                    validationSchema={Yup.object({
                      pnumber2: Yup.string().required("*Required"),
                      fname2: Yup.string().min(2, "Must be at least 2 characters").required("*Required"),
                      lname2: Yup.string().min(2, "Must be at least 2 characters").required("*Required"),
                      approxdate: Yup.string().required("*Required"),
                      acceptedTerms2: Yup.boolean()
                        .required("*Required")
                        .oneOf([true], "You must accept the terms and conditions"),
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setTimeout(() => {
                        handleReApplication(
                          '[["' +
                            new Date().toISOString() +
                            '", "' +
                            values.pnumber2 +
                            '", "' +
                            values.fname2 +
                            '", "' +
                            values.lname2 +
                            '", "' +
                            values.approxdate +
                            '", "' +
                            values.newinfo +
                            '"]]'
                        );
                        resetForm();
                        window.scrollTo(0, 0);
                        setOpen(true);
                        setSubmitting(false);
                      }, 2000);
                    }}
                  >
                    {(props) => (
                      /* the id on the <Form> is used by emailJS to get the form */
                      <Form onSubmit={props.handleSubmit} id="formikForm" className={styles.reapplicationForm}>
                        <h1>{lang === "en-US" ? "Re-Application Form" : "Återansökningsformulär"}</h1>

                        <CustomTextInput
                          label={lang === "en-US" ? "First name" : "Förnamn"}
                          name="fname2"
                          type="text"
                          className={styles.fname2}
                        />
                        <CustomTextInput
                          label={lang === "en-US" ? "Last name" : "Efternamn"}
                          name="lname2"
                          type="text"
                          className={styles.lname2}
                        />
                        <CustomTextInput
                          label={
                            lang === "en-US"
                              ? "Personal number - if not available then Date of Birth (year/month/day)"
                              : "Personnummer - om det inte finns skriv då födelsedatum (år/månad/dag)"
                          }
                          name="pnumber2"
                          type="text"
                          className={styles.pnumber2}
                        />
                        <CustomTextInput
                          label={
                            lang === "en-US"
                              ? "Approximate date of original application"
                              : "Ungefärligt datum för den ursprungliga ansökan"
                          }
                          name="approxdate"
                          type="text"
                          className={styles.approxdate}
                        />
                        <CustomTextInput
                          label={lang === "en-US" ? "Any new contact information" : "Eventuell ny kontaktinformation"}
                          name="newinfo"
                          type="text"
                          className={styles.newinfo}
                        />

                        <div className={styles.terms2}>
                          <CustomCheckbox name="acceptedTerms2">
                            {lang === "en-US"
                              ? "We hereby provide consent to Gothenburgs Preschool AB to store the above information for their waiting list. You can read more about the GDPR policy on our"
                              : "Vi ger härmed samtycke till Gothenburgs Preschool AB att lagra ovanstående information till deras väntelista. Du kan läsa mer om GDPR-policy på vår"}
                            <a href="/gdpr" rel="noopener noreferrer" target="_blank">
                              GDPR Page
                            </a>
                          </CustomCheckbox>
                        </div>
                        <br />
                        <button className={styles.submit} type="submit">
                          {props.isSubmitting ? "Loading..." : "Submit"}
                        </button>
                      </Form>
                    )}
                  </Formik>
                ) : (
                  <>
                    <br />
                    <Markdown children={applicationInfo.reApplicationContent} allowDangerousHtml={true} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Application;
