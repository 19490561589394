import React, { useState, useEffect } from "react";
import Layout from "../components/Layout/Layout";
import client from "../services/contentful.js";
import Markdown from "react-markdown";

const GDPR = () => {
  const [isLoading, setisLoading] = useState(true);
  const [gdprInfo, setGdprInfo] = useState([]);
  useEffect(
    () => {
      window.scrollTo(0, 0);
      client.getEntry("6kBsRocCLQeVaSb2hMzxsA" /* , { locale: lang } */).then((entry) => {
        setGdprInfo(entry.fields);
        setisLoading(false);
      });
    },
    [
      /* lang */
    ]
  );
  return <Layout height="30vh"> {isLoading ? "" : <Markdown children={gdprInfo.gdpr} />}</Layout>;
};

export default GDPR;
