import React, { useState } from "react";
import "./App.css";
import { Switch, Route } from "react-router";
import Home from "./pages/Home";
import Staff from "./pages/Staff";
import PreschoolConcepts from "./pages/PreschoolConcepts";
import OutdoorProgram from "./pages/OutdoorProgram";
import Application from "./pages/Application";
import Contact from "./pages/Contact";
import Masen from "./pages/Masen";
import GDPR from "./pages/GDPR";

function App() {
  const [lang, setLang] = useState("en-US");
  return (
    <div className="App">
      <Switch>
        <Route exact path="/preschool-concepts">
          <PreschoolConcepts lang={lang} setLang={setLang} />
        </Route>
        <Route exact path="/outdoor-program">
          <OutdoorProgram lang={lang} setLang={setLang} />
        </Route>
        <Route exact path="/application">
          <Application lang={lang} setLang={setLang} />
        </Route>
        <Route exact path="/contact">
          <Contact lang={lang} setLang={setLang} />
        </Route>
        <Route exact path="/masen">
          <Masen lang={lang} setLang={setLang} />
        </Route>
        <Route exact path="/staff">
          <Staff lang={lang} setLang={setLang} />
        </Route>
        <Route exact path="/gdpr">
          <GDPR lang={lang} setLang={setLang} />
        </Route>
        <Route exact path="/">
          <Home lang={lang} setLang={setLang} />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
