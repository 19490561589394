import React from "react";
import styles from "./ModalPopup.module.scss";

import checkmark from "../../assets/images/checkmark.svg";

const ModalPopup = ({ open, close, lang }) => {
  return (
    <>
      {open === true ? (
        <div id="myModal" className={styles.modal}>
          <div className={styles.modalContent}>
            <img src={checkmark} alt="success" />
            <h1>{lang === "en-US" ? "Thank you for applying!" : "Tack för din ansökan!"}</h1>
            <p>
              {lang === "en-US"
                ? "Your application has been sent and you will hear back from us soon."
                : "Din ansökan har skickats och du kommer snart att höra från oss."}
            </p>
            <button className={styles.modal_button} onClick={() => close()}>
              {lang === "en-US" ? "CONTINUE" : "FORTSÄTT"}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ModalPopup;
