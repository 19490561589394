import React, { useEffect, useState } from "react";
import styles from "./PreschoolConcepts.module.scss";
import Layout from "../components/Layout/Layout";
import client from "../services/contentful.js";
import Markdown from "react-markdown";

const PreschoolConcepts = ({ lang, setLang }) => {
  const [isLoading, setisLoading] = useState(true);
  const [content, setContent] = useState([]);
  const [image, setImage] = useState("");
  const [headerTitle, setHeaderTitle] = useState("PRESCHOOL CONCEPTS");
  const [concepts, setConcepts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    client.getEntry("FMEQVVwfNBNLAIiw7M6ir", { locale: lang }).then((entry) => {
      setContent(entry.fields);
      setHeaderTitle(entry.fields.headerTitle);
      if (entry.fields.headerImage !== undefined) {
        setImage("https://" + entry.fields.headerImage.fields.file.url);
      }
      setisLoading(false);
    });

    client.getEntries({ content_type: "concept", locale: lang }).then((entries) => {
      setConcepts(entries.items);
      setisLoading(false);
    });
  }, [lang]);

  return (
    <Layout title={headerTitle} image={image} lang={lang} setLang={setLang}>
      {isLoading ? (
        <></>
      ) : (
        <div className={styles.container}>
          <Markdown children={content.content} allowDangerousHtml={true} />

          <div className={styles.concepts}>
            {concepts.map((concept, index) => (
              <div className={styles.concept} key={index}>
                <h3>{concept.fields.title}</h3>
                <Markdown children={concept.fields.description} />
              </div>
            ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default PreschoolConcepts;
