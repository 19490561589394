import React, { useEffect, useState } from "react";
import styles from "./OutdoorProgram.module.scss";
import Layout from "../components/Layout/Layout";
import client from "../services/contentful.js";
import Markdown from "react-markdown";

const OutdoorProgram = ({ lang, setLang }) => {
  const [isLoading, setisLoading] = useState(true);
  const [outdoorProgram, setoutdoorProgram] = useState();
  const [image, setImage] = useState("");
  const [headerTitle, setHeaderTitle] = useState("OUTDOOR PROGRAM");

  useEffect(() => {
    window.scrollTo(0, 0);

    client
      .getEntry("3E2ZYbSxTIA6r7unDEgPwX", { locale: lang })
      .then((entry) => {
        setoutdoorProgram(entry.fields);
        setHeaderTitle(entry.fields.headerTitle);
        if (entry.fields.headerImage !== undefined) {
          setImage("https://" + entry.fields.headerImage.fields.file.url);
        }
        setisLoading(false);
      })
      .catch((error) => console.log(error));
  }, [lang]);

  return (
    <Layout title={headerTitle} image={image} lang={lang} setLang={setLang}>
      {isLoading ? (
        ""
      ) : (
        <div className={styles.content}>
          <div className={styles.puff}>
            <div className={styles.text}>
              <Markdown children={outdoorProgram.textOne} allowDangerousHtml={true} />
            </div>
            <img
              alt="outdoors"
              src={outdoorProgram.image !== undefined || null ? `https://${outdoorProgram.image.fields.file.url}` : ""}
            />
          </div>

          <Markdown children={outdoorProgram.textTwo} allowDangerousHtml={true} className={styles.texttwo} />

          <ul className={styles.images}>
            {outdoorProgram.images !== null || undefined
              ? outdoorProgram.images.map((image, index) => (
                  <li key={index} className={image.fields === undefined ? styles.none : ""}>
                    <img alt="outdoors" src={image.fields === undefined ? "" : `https://${image.fields.file.url}`} />
                  </li>
                ))
              : ""}
          </ul>
        </div>
      )}
    </Layout>
  );
};

export default OutdoorProgram;
