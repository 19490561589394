import React, { useEffect, useState } from "react";
import styles from "./Home.module.scss";
import Layout from "../components/Layout/Layout";
import client from "../services/contentful.js";
import Markdown from "react-markdown";
import gplogo from "../assets/images/gplogo.webp";

const Home = ({ lang, setLang }) => {
  const [isLoading, setisLoading] = useState(true);
  const [homeInfo, setHomeInfo] = useState([]);
  const [image, setImage] = useState("");
  const [headerTitle, setHeaderTitle] = useState("GOTHENBURGS PRESCHOOL");

  useEffect(() => {
    window.scrollTo(0, 0);
    client.getEntry("6QQPpi48QDZFiRwZWlHdu", { locale: lang }).then((entry) => {
      setHomeInfo(entry.fields);
      setHeaderTitle(entry.fields.headerText);
      if (entry.fields.headerImage.fields !== undefined) {
        setImage("https://" + entry.fields.headerImage.fields.file.url);
      }
      setisLoading(false);
    });
  }, [lang]);

  return (
    <Layout height="79vh" title={headerTitle} image={image} lang={lang} setLang={setLang}>
      <>
        {isLoading ? (
          ""
        ) : (
          <div className={styles.container}>
            <img src={gplogo} alt="" style={{margin: "0 auto", display: "block", maxWidth: "300px"}}/>

            <div className={styles.text}>
              <Markdown children={homeInfo.content} allowDangerousHtml={true} />
            </div>

            <ul className={styles.images}>
              {homeInfo.images !== null || undefined
                ? homeInfo.images.map((image, index) => (
                    <li key={index} className={image.fields === undefined ? styles.none : ""}>
                      <img
                        alt="ourchildren"
                        src={`https://${image.fields === undefined ? "" : image.fields.file.url}`}
                      />
                    </li>
                  ))
                : ""}
            </ul>
          </div>
        )}
      </>
    </Layout>
  );
};

export default Home;
